import { createSlice, nanoid } from "@reduxjs/toolkit";

import axiosInstance from "utils/api";
import { errorToast } from "utils/helper";

const initialState = {
  durationOptions: [],
  tonalityOptions: [],
  dimensionsOptions: [],
  imageTypesOptions: [],
  imageThemesOptions: [],
  imageColorsOptions: [],
  elementsOptions: [],
  animationsOptions: [],
  transitionsOptions: [],
  logoAlignmentOptions: [],
};

const MiscellaneousSlice = createSlice({
  name: "Miscellaneous",
  initialState,
  reducers: {
    setDurationOptions: (state, action) => {
      const { duration } = action.payload || {};

      // Set the duration options in the state to be used in the dropdown component
      state.durationOptions =
        duration?.map(({ duration_seconds }, index) => ({
          id: index + 1,
          value: duration_seconds.toString(),
          title: `${duration_seconds} seconds`,
        })) || [];
    },
    setTonalityOptions: (state, action) => {
      const { tonality } = action.payload || {};

      // Set the tonality options in the state to be used in the dropdown component
      state.tonalityOptions =
        tonality?.map(({ tonality }, index) => ({
          id: index + 1,
          value: tonality,
          title: tonality,
        })) || [];
    },
    setDimensionsOptions: (state, action) => {
      const { dimensions } = action.payload || {};

      // Set the dimensions options in the state to be used in the dropdown component
      state.dimensionsOptions = dimensions?.map(
        ({ name, dimension_type, aspect_ratio, width, height }, index) =>
          ({
            id: index + 1,
            value: name,
            title: `${dimension_type} (${aspect_ratio})`,
          } || [])
      );
    },
    setImageTypesOptions: (state, action) => {
      state.imageTypesOptions =
        action.payload?.map(({ id, name }) => ({
          id,
          value: name,
          title: name,
        })) || [];

      state.imageTypesOptions.push({
        id: nanoid(),
        value: "Other",
        title: "Other",
      });
    },
    setImageThemesOptions: (state, action) => {
      state.imageThemesOptions =
        action.payload?.map(({ id, name }) => ({
          id,
          value: name,
          title: name,
        })) || [];
      state.imageThemesOptions.push({
        id: nanoid(),
        value: "Other",
        title: "Other",
      });
    },
    setImageColorsOptions: (state, action) => {
      state.imageColorsOptions =
        action.payload?.map(({ id, name }) => ({
          id,
          value: name,
          title: name,
        })) || [];
      state.imageColorsOptions.push({
        id: nanoid(),
        value: "Other",
        title: "Other",
      });
    },
    setElementsOptions: (state, action) => {
      state.elementsOptions =
        action.payload?.map(({ id, name }) => ({
          id,
          value: name,
          title: name,
        })) || [];
      state.elementsOptions.push({
        id: nanoid(),
        value: "Other",
        title: "Other",
      });
    },
    setAnimationsOptions: (state, action) => {
      state.animationsOptions =
        action.payload?.map(({ id, name }) => ({
          id,
          value: name,
          title: name,
        })) || [];
    },
    setTransitionsOptions: (state, action) => {
      state.transitionsOptions =
        action.payload?.map(({ id, name }) => ({
          id,
          value: name,
          title: name,
        })) || [];
    },
    setLogoAlignmentOptions: (state, action) => {
      state.logoAlignmentOptions =
        action.payload?.map(({ id, name }) => ({
          id,
          value: name,
          title: name,
        })) || [];
    },
  },
});

export const getDurationOptionsAction = () => async (dispatch) => {
  try {
    const { data } = await axiosInstance.get("/accounts/getduration/");
    dispatch(setDurationOptions(data));
  } catch (error) {
    errorToast({ dispatch, error });
  }
};

export const getTonalityOptionsAction = () => async (dispatch) => {
  try {
    const { data } = await axiosInstance.get("/accounts/gettonality/");
    dispatch(setTonalityOptions(data));
  } catch (error) {
    errorToast({ dispatch, error });
  }
};

export const getDimensionsOptionsAction = () => async (dispatch) => {
  try {
    const { data } = await axiosInstance.get("/accounts/getdimensions/");
    dispatch(setDimensionsOptions(data));
  } catch (error) {
    errorToast({ dispatch, error });
  }
};

export const getImageTypesOptionsAction = () => async (dispatch) => {
  try {
    const { data } = await axiosInstance.get("/videogenerator/getimagetypes/");
    dispatch(setImageTypesOptions(data));
  } catch (error) {
    errorToast({ dispatch, error });
  }
};

export const getImageThemesOptionsAction = () => async (dispatch) => {
  try {
    const { data } = await axiosInstance.get("/videogenerator/getimagethemes/");
    dispatch(setImageThemesOptions(data));
  } catch (error) {
    errorToast({ dispatch, error });
  }
};

export const getImageColorsOptionsAction = () => async (dispatch) => {
  try {
    const { data } = await axiosInstance.get("/videogenerator/getcolors/");
    dispatch(setImageColorsOptions(data));
  } catch (error) {
    errorToast({ dispatch, error });
  }
};

export const getElementsOptionsAction = () => async (dispatch) => {
  try {
    const { data } = await axiosInstance.get("/videogenerator/getelements/");
    dispatch(setElementsOptions(data));
  } catch (error) {
    errorToast({ dispatch, error });
  }
};

export const getAnimationOptionsAction = () => async (dispatch) => {
  try {
    const { data } = await axiosInstance.get("/videogenerator/getanimations/");
    dispatch(setAnimationsOptions(data));
  } catch (error) {
    errorToast({ dispatch, error });
  }
};
export const getTransitionsOptionAction = () => async (dispatch) => {
  try {
    const { data } = await axiosInstance.get("/videogenerator/gettransitions/");
    dispatch(setTransitionsOptions(data));
  } catch (error) {
    errorToast({ dispatch, error });
  }
};

export const getLogoAlignmentOptionsAction = () => async (dispatch) => {
  try {
    const { data } = await axiosInstance.get("/videogenerator/getlocations/");
    dispatch(setLogoAlignmentOptions(data));
  } catch (error) {
    errorToast({ dispatch, error });
  }
};

export const selectDimensionsOptions = (state) => state.Misc.dimensionsOptions;
export const selectDurationOptions = (state) => state.Misc.durationOptions;
export const selectTonalityOptions = (state) => state.Misc.tonalityOptions;
export const selectImageTypesOptions = (state) => state.Misc.imageTypesOptions;
export const selectImageThemesOptions = (state) =>
  state.Misc.imageThemesOptions;
export const selectImageColorsOptions = (state) =>
  state.Misc.imageColorsOptions;
export const selectElementsOptions = (state) => state.Misc.elementsOptions;
export const selectAnimationsOptions = (state) => state.Misc.animationsOptions;
export const selectTransitionsOptions = (state) =>
  state.Misc.transitionsOptions;
export const selectLogoAlignmentOptions = (state) =>
  state.Misc.logoAlignmentOptions;

export const {
  setDurationOptions,
  setDimensionsOptions,
  setTonalityOptions,
  setElementsOptions,
  setImageColorsOptions,
  setImageThemesOptions,
  setImageTypesOptions,
  setAnimationsOptions,
  setTransitionsOptions,
  setLogoAlignmentOptions,
} = MiscellaneousSlice.actions;

export default MiscellaneousSlice.reducer;
