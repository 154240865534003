import React, { useEffect, lazy, Suspense } from "react";
import {
  Route,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import ErrorFallback from "components/ErrorFallback";
import ProtectedRoute from "components/ProtectedRoute";
import { ROUTES } from "./RouterConfig";
import { selectIsLoggedIn } from "store/AuthSlice";
import {
  getDimensionsOptionsAction,
  getDurationOptionsAction,
  getTonalityOptionsAction,
  getElementsOptionsAction,
  getImageTypesOptionsAction,
  getImageThemesOptionsAction,
  getImageColorsOptionsAction,
  getAnimationOptionsAction,
  getTransitionsOptionAction,
  getLogoAlignmentOptionsAction,
} from "store/MiscellaneousSlice";
import { getVoiceDataAction } from "store/UserSlice";
import Layout from "Layouts";

// Lazy-loaded components
const AuthPage = lazy(() => import("pages/AuthPage"));
const LibraryPage = lazy(() => import("pages/LibraryPage"));
const EditVideoPage = lazy(() => import("pages/EditVideoPage"));
const VideoGalleryPage = lazy(() => import("pages/VideoGallery"));
const VideoMakerResultPage = lazy(() => import("pages/VideoMakerResultPage"));
const GalleryPage = lazy(() => import("pages/GalleryPage"));
const VideoMakerPrompterPage = lazy(() => import("pages/VideoMakerPromptPage"));
const FabricCanvas = lazy(() => import("pages/FabricCanvas"));

const AuthenticatedRoutes = [
  { path: ROUTES.VIDEO_MAKER, element: <VideoMakerPrompterPage /> },
  { path: ROUTES.GALLERY, element: <GalleryPage /> },
  { path: ROUTES.LIBRARY, element: <LibraryPage /> },
  { path: ROUTES.IMAGE_EDITOR, element: <FabricCanvas /> },
  { path: `${ROUTES.VIDEO_MAKER}/:id`, element: <VideoMakerResultPage /> },
  {
    path: `${ROUTES.LIBRARY}/:id/${ROUTES.VIDEO_GALLERY}`,
    element: <VideoGalleryPage />,
  },
  {
    path: `${ROUTES.LIBRARY}/:id/${ROUTES.EDIT_VIDEO}`,
    element: <EditVideoPage />,
  },
];

const UnauthenticatedRoutes = [{ path: ROUTES.AUTH, element: <AuthPage /> }];

const MyRoutes = () => {
  const isAuthenticated = useSelector(selectIsLoggedIn);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isAuthenticated) return;
    dispatch(getDimensionsOptionsAction());
    dispatch(getDurationOptionsAction());
    dispatch(getTonalityOptionsAction());
    dispatch(getElementsOptionsAction());
    dispatch(getImageTypesOptionsAction());
    dispatch(getImageThemesOptionsAction());
    dispatch(getImageColorsOptionsAction());
    dispatch(getAnimationOptionsAction());
    dispatch(getTransitionsOptionAction());
    dispatch(getLogoAlignmentOptionsAction());
    dispatch(getVoiceDataAction());
  }, [dispatch, isAuthenticated]);

  const renderFallback = (
    <Layout>
      <div className="flex items-center justify-center h-screen">
        <div className="spin-loader-xl"></div>
      </div>
    </Layout>
  );

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route
          path="*"
          element={
            <Navigate to={isAuthenticated ? ROUTES.VIDEO_MAKER : ROUTES.AUTH} />
          }
        />
        {AuthenticatedRoutes.map((route, index) => (
          <Route
            key={index}
            element={<ProtectedRoute isAuthenticated={isAuthenticated} />}
            errorElement={<ErrorFallback />}
          >
            <Route
              path={route.path}
              element={
                <Suspense fallback={renderFallback}>{route.element}</Suspense>
              }
            />
          </Route>
        ))}
        {UnauthenticatedRoutes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={
              <Suspense fallback={renderFallback}>{route.element}</Suspense>
            }
          />
        ))}
      </Route>
    )
  );

  return (
    <Suspense fallback={renderFallback}>
      <RouterProvider router={router} />
    </Suspense>
  );
};

export default MyRoutes;
