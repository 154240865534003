import { createSlice } from "@reduxjs/toolkit";

import axiosInstance from "utils/api";
import { HTTP_REQUEST_METHODS } from "utils/constants";
import { errorToast } from "utils/helper";

const initialState = {
  scriptData: [],
  editedPartIds: [],
  videoMakerResultStateData: {},
};

const VideoMakerSlice = createSlice({
  name: "VideoMaker",
  initialState,
  reducers: {
    setScriptData: (state, action) => {
      state.scriptData = action.payload;
    },
    setEditedPartIds: (state, action) => {
      const { id } = action.payload;
      if (!state.editedPartIds.includes(id)) {
        state.editedPartIds.push(id);
      }
    },
    removeEditedPartId: (state, action) => {
      state.editedPartIds = state.editedPartIds.filter(
        (id) => id !== action.payload
      );
    },
    setVideoMakerResultStateData: (state, action) => {
      state.videoMakerResultStateData = action.payload;
    },
    clearVideoMakerState: (state) => {
      state.videoMakerResultStateData = {};
    },
  },
});

export const generateScriptAction =
  ({ payload, setButtonLoader, onSuccess }) =>
  async (dispatch) => {
    try {
      setButtonLoader(true);
      await axiosInstance({
        method: HTTP_REQUEST_METHODS.POST,
        url: "/videogenerator/generateScript/",
        data: payload,
      });
      onSuccess();
    } catch (error) {
      errorToast({ dispatch, error });
      setButtonLoader(false);
    }
  };

export const selectScriptData = (state) => state.VideoMaker.scriptData;
export const selectEditedPartIds = (state) => state.VideoMaker.editedPartIds;
export const selectVideoMakerResultStateData = (state) =>
  state.VideoMaker.videoMakerResultStateData;

export const {
  setScriptData,
  removeEditedPartId,
  setEditedPartIds,
  setVideoMakerResultStateData,
} = VideoMakerSlice.actions;

export default VideoMakerSlice.reducer;
